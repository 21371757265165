<template>
  <div class="containerx ma-6">
    <v-row>
      <v-col cols="12" class="pa-0">
        <div
          class="d-flex flex-column flex-grow-1 justify-space-between mt-3 card flex-wrap mt-4"
        >
          <template
            v-if="
              stockLevelVariationsForLubes &&
                stockLevelVariationsForLubes.labels.length > 0
            "
          >
            <h3 class=" font font-weight-medium font-size-md text-uppercase">
              Stock Variations Over Period for lubes
            </h3>
            <p class=" font blue-grey--text font-size-sm">
              Overview of the stock variations over period for lubes
            </p>

            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <div
                v-for="(chart, index) in stockLevelVariationsForLubes.series"
                :key="index"
                class="d-flex flex-column flex-grow-1  "
                style="flex-basis: 40%"
              >
                <v-progress-circular indeterminate v-if="listLoading" />
                <apexcharts
                  class="d-flex box-shadow-light ma-1"
                  height="300"
                  :options="{
                    ...chartOptions,
                    chart: {
                      height: 300
                    },
                    legend: {
                      position: 'bottom',
                      fontSize: '15px',
                      fontWeight: '600'
                    },
                    plotOptions: {
                      borderRadius: 50
                    },
                    markers: {
                      size: 10
                    },
                    yaxis: [
                      {
                        min: 0,
                        title: {
                          text: 'Quantity'
                        }
                      }
                    ],
                    xaxis: {
                      type: 'Date',
                      categories: stockLevelVariationsForLubes.labels,
                      label: {
                        style: {
                          fontWeight: 'bold',
                          colors: ['black']
                        }
                      },
                      tooltip: {
                        enabled: true,
                        style: {
                          fontSize: 15
                        }
                      }
                    }
                  }"
                  :series="chart.series"
                ></apexcharts>
              </div>
            </div>
          </template>
          <div
            v-else
            class="d-flex flex-column justify-center flex-grow-1 mt-10"
            style="align-items:center"
          >
            <empty-page
              :title="
                'No lube stock variations available for the selected duration'
              "
              :subTitle="
                'Stock level variations of total lubes sold against total lubes left  will show up here'
              "
              :image="require(`@/assets/documents.svg`)"
              imageWidth="150px"
            />
            <v-progress-circular v-if="listLoading" indeterminate />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import moment from "moment";
import EmptyPage from "../../components/EmptyPage.vue";
export default {
  name: "StockLevelsOverview",
  components: {
    EmptyPage
  },
  props: {
    years: {
      type: Array,
      default: () => []
    },
    currency: { type: Array, default: () => [] },
    period: {
      type: Object,
      default: null
    },
    range: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      menu: false,
      dates: [],
      items: ["Sales Overview", "Deliveries"],
      durations: [
        { text: "1 week", number: 1, type: "week" },
        { text: "2 weeks", number: 2, type: "weeks" },
        { text: "3 weeks", number: 3, type: "weeks" },
        { text: "1 Month", number: 1, type: "month" }
      ],
      selectedDuration: 0,
      chartOptions: {
        chart: {
          height: 300
        },
        colors: ["#2196F3", "#b20000"],
        dataLabels: {
          enabled: false
        },
        // stroke: {
        //   curve: "smooth"
        // },
        xaxis: {
          type: "Lubes Names",
          categories: []
        }
      },

      series: [],
      search: ""
    };
  },
  computed: {
    ...mapGetters({
      stockLevelVariationsForLubes: "reports/getStockLevelVariationsLubes",
      listLoading: "getIsListLoading"
    })
  },
  watch: {
    period: debounce(function(date) {
      if (date !== null) {
        this.$store.dispatch("reports/stockLevelVariationsForLubes", {
          ...date
        });
      }
    }, 400),
    range(dates) {
      const [startDate, endDate] = dates;
      if (dates.length === 2 && moment(startDate).isAfter(moment(endDate))) {
        this.$store.dispatch(
          "showSnackBar",
          {
            message: `Invalid date range provided`,
            status: "red",
            snackBar: true
          },
          { root: true }
        );
        return;
      }
      if (dates.length === 2) {
        this.$store.dispatch("reports/stockLevelVariationsForLubes", {
          query: `?hasRange=true&startDate=${startDate}&endDate=${endDate}`,
          number: 0,
          type: "custom"
        });
      }
    }
  },

  created() {
    this.$store.dispatch("reports/stockLevelVariationsForLubes", {
      ...this.period
    });
  }
};
</script>
