var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"containerx ma-6"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 justify-space-between mt-3 card flex-wrap mt-4"},[(
            _vm.stockLevelVariationsForLubes &&
              _vm.stockLevelVariationsForLubes.labels.length > 0
          )?[_c('h3',{staticClass:" font font-weight-medium font-size-md text-uppercase"},[_vm._v(" Stock Variations Over Period for lubes ")]),_c('p',{staticClass:" font blue-grey--text font-size-sm"},[_vm._v(" Overview of the stock variations over period for lubes ")]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},_vm._l((_vm.stockLevelVariationsForLubes.series),function(chart,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1  ",staticStyle:{"flex-basis":"40%"}},[(_vm.listLoading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_c('apexcharts',{staticClass:"d-flex box-shadow-light ma-1",attrs:{"height":"300","options":Object.assign({}, _vm.chartOptions,
                  {chart: {
                    height: 300
                  },
                  legend: {
                    position: 'bottom',
                    fontSize: '15px',
                    fontWeight: '600'
                  },
                  plotOptions: {
                    borderRadius: 50
                  },
                  markers: {
                    size: 10
                  },
                  yaxis: [
                    {
                      min: 0,
                      title: {
                        text: 'Quantity'
                      }
                    }
                  ],
                  xaxis: {
                    type: 'Date',
                    categories: _vm.stockLevelVariationsForLubes.labels,
                    label: {
                      style: {
                        fontWeight: 'bold',
                        colors: ['black']
                      }
                    },
                    tooltip: {
                      enabled: true,
                      style: {
                        fontSize: 15
                      }
                    }
                  }}),"series":chart.series}})],1)}),0)]:_c('div',{staticClass:"d-flex flex-column justify-center flex-grow-1 mt-10",staticStyle:{"align-items":"center"}},[_c('empty-page',{attrs:{"title":'No lube stock variations available for the selected duration',"subTitle":'Stock level variations of total lubes sold against total lubes left  will show up here',"image":require("@/assets/documents.svg"),"imageWidth":"150px"}}),(_vm.listLoading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }